import './instrument.js'
import './reset.css'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import eruda from 'eruda'
import { lazy, Suspense, useEffect, useState } from 'react'
import { WebAppInitData } from '@twa-dev/types'
import WebApp from '@twa-dev/sdk'
import GameLayout from './layouts/Game'
import GameSetupLayout from '@/layouts/GameSetup'
import { routes } from '@/shared/constants/routes.ts'
import { loginRequest } from '@/auth/api/login.ts'
import { useCustomQueryWithParams } from '@/shared/hooks/useCustomQueryWithParams.ts'
import { GET_PROFILE } from '@/auth/api/constants.ts'
import { authApi } from '@/auth/api'
import MenuLayout from '@/layouts/Menu'
import { shouldLogin } from '@/utils/auth/login.ts'
import AboutLayout from '@/layouts/AboutLayout'
import ChangingText from '@/shared/components/ChangingText'
import { useProfile } from '@/shared/contexts/ProfileContext.tsx'

const GameLazy = lazy(() => import('@/pages/Game'))
const GameSetupLazy = lazy(() => import('@/pages/GameSetup'))
const MenuLazy = lazy(() => import('@/pages/Menu'))
const AboutLazy = lazy(() => import('@/pages/About'))

function App() {
    const environment = import.meta.env.VITE_ENVIRONMENT
    if (environment !== 'production') {
        eruda.init()
    }

    const [initData, setInitData] = useState<WebAppInitData>()
    const [loaded, setLoaded] = useState(false)
    const [loadingAnimationShowed, setLoadingAnimationShowed] = useState(false)
    const { setUser, user } = useProfile()

    const accessToken = localStorage.getItem('accessToken')

    const { data: userProfile, refetch } = useCustomQueryWithParams(GET_PROFILE, authApi.getProfile, {
        enabled: !!accessToken,
        retry: 3,
    })

    useEffect(() => {
        if (user) {
            console.log(user)
        }
    }, [user])

    useEffect(() => {
        const webAppInitData = WebApp.initDataUnsafe
        setTimeout(() => {
            setLoadingAnimationShowed(true)
        }, 3000)
        if (!webAppInitData.user) {
            return
        }

        setInitData(webAppInitData)

        if (userProfile?.data) {
            setUser({
                ...webAppInitData.user,
                backendUserId: userProfile.data.id,
                id: webAppInitData.user.id,
                balance: userProfile.data.balance,
            })
            setLoaded(true)
        }
    }, [setUser, userProfile])

    useEffect(() => {
        if (!initData) {
            console.log('no initData')
            return
        }
        if (shouldLogin(initData, accessToken)) {
            loginRequest(initData)
                .then((res: { accessToken: string }) => {
                    localStorage.setItem('accessToken', res.accessToken)
                    refetch()
                })
                .catch(() => console.error('Something went wrong while login request'))
        }
    }, [accessToken, initData, refetch])

    useEffect(() => {
        const tg = window.Telegram.WebApp
        tg.expand()
        tg.setHeaderColor('#000000')
        tg.disableVerticalSwipes()
    }, [])

    return loaded && loadingAnimationShowed ? (
        <Routes>
            <Route path={routes.menu} element={<MenuLayout />}>
                <Route
                    index
                    element={
                        <Suspense fallback="Loading">
                            <MenuLazy />
                        </Suspense>
                    }
                />
            </Route>
            <Route path={routes.game.setup} element={<GameSetupLayout />}>
                <Route
                    index
                    element={
                        <Suspense fallback="Loading">
                            <GameSetupLazy />
                        </Suspense>
                    }
                />
            </Route>
            <Route path={routes.game.base} element={<GameLayout />}>
                <Route
                    index
                    element={
                        <Suspense fallback="Loading">
                            <GameLazy />
                        </Suspense>
                    }
                />
            </Route>
            <Route path={routes.about} element={<AboutLayout />}>
                <Route
                    index
                    element={
                        <Suspense fallback="Loading">
                            <AboutLazy />
                        </Suspense>
                    }
                />
            </Route>
        </Routes>
    ) : (
        <ChangingText />
    )
}

export default App
