import { createContext, useContext, useState } from 'react'
import { WebAppUser } from '@twa-dev/types'

export interface IUser extends WebAppUser {
    backendUserId: string
    balance: number
}

const ProfileContext = createContext<{
    user: IUser | null
    setUser: (user: IUser | null) => void
} | null>(null)

export function ProfileProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<IUser | null>(null)
    return <ProfileContext.Provider value={{ user, setUser }}>{children}</ProfileContext.Provider>
}

export const useProfile = () => {
    const context = useContext(ProfileContext)
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider')
    }
    return context
}
