import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import s from './GameLayout.module.css'

function GameLayout() {
    return (
        <div className={s.container}>
            <Suspense fallback="Loading...">
                <Outlet />
            </Suspense>
        </div>
    )
}

export default GameLayout
