import { Avatar } from 'antd-mobile'
import s from './Header.module.css'
import tokenIcon from '@/shared/icons/token.png'
import { useProfile } from '@/shared/contexts/ProfileContext.tsx'

interface HeaderProps {
    balance?: number
    username?: string
}

export default function Header({ username, balance }: HeaderProps) {
    const { user } = useProfile()
    return (
        <div className={s.container}>
            {user?.photo_url && <Avatar className={s.avatar} src={user?.photo_url ?? ''} />}
            {username && (
                <div className={s.userInfo}>
                    <span>{username || 'no username'}</span>
                </div>
            )}
            <div className={s.balance}>
                <span>{balance || 'no balance'}</span>
                <img alt="token" src={tokenIcon} width={30} height={30} />
            </div>
        </div>
    )
}
