import { AxiosError } from 'axios'
import { useQuery, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query'

const defaultQueryOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
}

export function useCustomQueryWithParams<TData>(
    queryKey: QueryKey,
    queryFn: () => Promise<TData>,
    options?: UseQueryOptions<TData, AxiosError>
): UseQueryResult<TData, AxiosError> {
    const { error, ...rest } = useQuery<TData, AxiosError>(queryKey, queryFn, {
        ...defaultQueryOptions,
        ...options,
        retry: (failureCount: number, error: AxiosError) => {
            if (error?.response?.status === 401) {
                console.log('401 detected')
                return false
            }

            if (error?.response?.status === 429) {
                return false
            }

            if (typeof options?.retry === 'function') {
                return options.retry(failureCount, error)
            }

            if (typeof options?.retry === 'boolean') {
                return options.retry
            }

            const maxRetries = typeof options?.retry === 'number' ? options.retry : 3
            return failureCount < maxRetries
        },
    })

    return { error, ...rest } as UseQueryResult<TData, AxiosError>
}
