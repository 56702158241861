export const routes = {
    menu: '/',
    game: {
        base: '/game',
        setup: '/game-setup',
    },
    loading: {
        base: '/loading',
    },
    about: '/about',
} as const
