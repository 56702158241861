import { AxiosResponse } from 'axios'
import { WebAppInitData } from '@twa-dev/types'
import { ILoginRequestResponse } from './login.ts'
import { axiosClient } from '@/entities/httpClient'

const urls = {
    getProfile: '/users/profile',
    login: '/auth/login',
}

interface IProfileResponse {
    id: string
    tgId: number
    balance: number
}

export const authApi = {
    async getProfile(): Promise<AxiosResponse<IProfileResponse>> {
        return axiosClient.request({
            url: urls.getProfile,
            method: 'GET',
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        })
    },
    async login(data: WebAppInitData): Promise<AxiosResponse<ILoginRequestResponse>> {
        return axiosClient.request({
            url: urls.login,
            method: 'POST',
            data,
        })
    },
}
