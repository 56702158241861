import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import App from './App.tsx'
import './index.css'
import { SocketProvider } from './shared/contexts/GameSocket.tsx'
import { ProfileProvider } from '@/shared/contexts/ProfileContext.tsx'
import { MatchProvider } from '@/shared/contexts/MatchContext.tsx'
const queryClient = new QueryClient()

export function Main() {
    return <App />
}

function parseErrorMessage(error: any) {
    if (!error) {
        return 'Неизвестная ошибка'
    }

    return error.message || 'Не удалось распарсить ошибку'
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Sentry.ErrorBoundary
            fallback={({ error }) => (
                <div>
                    <h2>Произошла ошибка!</h2>
                    <pre>{parseErrorMessage(error)}</pre>
                </div>
            )}
        >
            <BrowserRouter>
                <SocketProvider>
                    <ProfileProvider>
                        <MatchProvider>
                            <QueryClientProvider client={queryClient}>
                                <Main />
                            </QueryClientProvider>
                        </MatchProvider>
                    </ProfileProvider>
                </SocketProvider>
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    </React.StrictMode>
)
