import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import s from './s.module.css'
import Header from '@/shared/components/Header'
import { useProfile } from '@/shared/contexts/ProfileContext.tsx'

function MenuLayout() {
    const { user } = useProfile()

    return (
        <div className={s.container}>
            <Header balance={user?.balance} />
            <div className={s.content}>
                <Suspense fallback="Loading...">
                    <Outlet />
                </Suspense>
            </div>
        </div>
    )
}

export default MenuLayout
