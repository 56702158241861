import { jwtDecode } from 'jwt-decode'

export function getTokenExpiration(accessToken: string) {
    try {
        const decodedToken = jwtDecode(accessToken)
        const expirationTimeInSeconds = decodedToken.exp

        if (!expirationTimeInSeconds) {
            return console.error('no expiration value')
        }

        return new Date(expirationTimeInSeconds * 1000)
    } catch (error) {
        console.error('Error due decode the token:', error)
        return null
    }
}
