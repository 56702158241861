import { createContext, useContext, useState } from 'react'

const MatchContext = createContext<{
    setOpponentId: (opponentId: string | null) => void
    opponentId: string | null
    setMatchId: (matchId: string) => void
    matchId: string
    userLifes: number | null
    opponentLifes: number | null
    setOpponentLifes: (opponentLifes: number | null) => void
    setUserLifes: (userLifes: number | null) => void
} | null>(null)

export function MatchProvider({ children }: { children: React.ReactNode }) {
    const [matchId, setMatchId] = useState<string>('')
    const [opponentLifes, setOpponentLifes] = useState<number | null>(null)
    const [userLifes, setUserLifes] = useState<number | null>(null)
    const [opponentId, setOpponentId] = useState<string | null>(null)

    return (
        <MatchContext.Provider
            value={{
                opponentLifes,
                opponentId,
                userLifes,
                setUserLifes,
                setOpponentLifes,
                setOpponentId,
                setMatchId,
                matchId,
            }}
        >
            {children}
        </MatchContext.Provider>
    )
}

export const useMatch = () => {
    const context = useContext(MatchContext)
    if (!context) {
        throw new Error('useMatch must be used within a MatchProvider')
    }
    return context
}
