import { WebAppInitData } from '@twa-dev/types'
import { getTokenExpiration } from '@/shared/utils/auth/checkJWTExpiration.ts'

export const shouldLogin = (initData: WebAppInitData, accessToken: string | undefined | null): boolean => {
    if (!initData) {
        return false
    }

    if (!accessToken) {
        return true
    }

    const jwtExpirationDate = getTokenExpiration(accessToken)
    if (!jwtExpirationDate) {
        return true
    }

    const now = new Date()
    const timeDifferenceInDays = (jwtExpirationDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)

    return timeDifferenceInDays < 5
}
