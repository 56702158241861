import { useState, useEffect } from 'react'
import s from './ChangingText.module.css'

function FlashingText() {
    const [currentWord, setCurrentWord] = useState('')
    const flashInterval = 300
    const pauseInterval = 500

    useEffect(() => {
        const words = ['TSU', 'E', 'FA']

        let wordIndex = 0
        let intervalId: NodeJS.Timeout

        const updateWord = () => {
            setCurrentWord(words[wordIndex])
            wordIndex = (wordIndex + 1) % words.length

            if (wordIndex === 0) {
                clearInterval(intervalId)
                setTimeout(() => {
                    intervalId = setInterval(updateWord, flashInterval)
                }, pauseInterval)
            }
        }

        intervalId = setInterval(updateWord, flashInterval)

        return () => clearInterval(intervalId)
    }, [flashInterval, pauseInterval])

    return (
        <div className={s.container}>
            <div className={s.text}>{currentWord}</div>
        </div>
    )
}

export default FlashingText
