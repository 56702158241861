import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const PUBLIC_DOMAIN = import.meta.env.VITE_PUBLIC_DOMAIN
const WIDGET_DOMAIN = import.meta.env.VITE_WIDGET_DOMAIN
const NODE_DOMAIN = import.meta.env.VITE_NODE_DOMAIN

export const axiosClient = axios.create({
    baseURL: PUBLIC_DOMAIN,
    withCredentials: true,
})

export const widgetClient = axios.create({
    baseURL: WIDGET_DOMAIN,
    withCredentials: true,
})

export const nodeServerClient = axios.create({
    baseURL: NODE_DOMAIN,
})

const middleware = [
    function (config: any) {
        const xTraceUUID = uuidv4()
        const spanUUID = uuidv4()

        config.headers['X-Trace'] = xTraceUUID
        config.headers['X-Span-Id'] = spanUUID

        return config
    },
    function (error: any) {
        return Promise.reject(error)
    },
]

axiosClient.interceptors.request.use(middleware[0], middleware[1])
widgetClient.interceptors.request.use(middleware[0], middleware[1])
