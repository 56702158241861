import { WebAppInitData } from '@twa-dev/types'
import { authApi } from './index.tsx'

export interface ILoginRequestResponse {
    accessToken: string
}
export async function loginRequest(initData: WebAppInitData): Promise<ILoginRequestResponse> {
    const response = await authApi.login(initData)

    if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`)
    }

    return { accessToken: response.data.accessToken }
}
